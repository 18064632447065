import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "*",
    redirect: "/dashboard/",
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/rekam-medik/",
    component: () => import("../pages/antrian/List.vue"),
  },
  {
    path: "/rekam-medik/form/:id",
    component: () => import("../pages/rekam-medik/Form.vue"),
  },
  {
    path: "/rekam-medik/detail/:id",
    component: () => import("../pages/rekam-medik/Detail.vue"),
  },
  {
    path: "/rekam-medik/resep/:id",
    component: () => import("../pages/rekam-medik/Resep.vue"),
  },
  {
    path: "/kunjungan",
    component: () => import("../pages/kunjungan/List.vue"),
  },
  {
    path: "/kunjungan/detail/:id",
    component: () => import("../pages/kunjungan/Detail.vue"),
  },
  {
    path: "/ruang-pelayanan",
    component: () => import("../pages/ruang-pelayanan/List.vue"),
  },
  {
    path: "/ruang-pelayanan/create",
    component: () => import("../pages/ruang-pelayanan/Form.vue"),
  },
  {
    path: "/stok-obat",
    component: () => import("../pages/obat/List.vue"),
  },
  {
    path: "/stok-obat/create",
    component: () => import("../pages/obat/Form.vue"),
  },
  {
    path: "/stok-obat/update/:id",
    component: () => import("../pages/obat/Update.vue"),
  },
  {
    path: "/stok-obat/import",
    component: () => import("../pages/obat/Import.vue"),
  },
  {
    path: "/profil",
    component: () => import("../pages/profil/Profil.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/eregister/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
