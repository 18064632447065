<script>
const sidebarMenu = [
  {
    path: "/dashboard",
    icon: "fa fa-display f-s-20",
    title: "Dashboard",
  },
  {
    path: "/rekam-medik",
    icon: "fa fa-stethoscope f-s-20",
    title: "Rekam Medik",
  },
  {
    path: "/kunjungan",
    icon: "fa fa-history f-s-20",
    title: "Riwayat Kunjungan",
  },
  {
    path: "/ruang-pelayanan",
    icon: "fa fa-medkit f-s-20",
    title: "Ruang Pelayanan",
  },
  {
    path: "/stok-obat",
    icon: "fa fa-pills f-s-20",
    title: "Stok Obat",
  },
];

export default sidebarMenu;
</script>
